* {
	box-sizing: border-box;
}

:root {
	--base: #02ce94;
	--blue: #003459;
}

body {
	margin: 0;
	font-family: "Montserrat", sans-serif;
	font-weight: 400;
}

img {
	max-width: 100%;
}

/* Typography */

.module-title {
	font-size: 2rem;
	margin-top: 0;
	text-align: center;
}

.module-description {
	font-size: 1.2rem;
}

.section-header {
	text-align: center;
	font-size: 2rem;
	margin-bottom: 0;
}

.content-header {
	font-size: 1.25rem;
}

h5 {
	margin: 20px;
}

a {
	color: #003459;
}

h1 a {
	color: black;
	text-decoration: none;
}

.btn-custom {
	display: block;
	margin: 10px auto;
	padding: 1.5em 3.15em;
	font-size: 0.75rem;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 1px;
	border: 0;
	cursor: pointer;
	background: #003459;
	color: white;
}

.page-header {
	text-align: center;
}

/* Layout */

.container {
	width: 95%;
	margin: 0 auto;
}

.flex-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

@media (min-width: 900px) {
	.flex-container {
		flex-direction: row;
	}

	.container {
		width: 85%;
	}
}

/* Header */

.container-header {
	justify-content: space-between;
	align-items: center;
}

nav a {
	margin: 0 20px;
}

/* Home */

.home-main {
	color: black;
}

.home-main p {
	margin: 1em 0;
	font-size: 1.05rem;
}

.course-types {
	padding: 20px 0;
}

@media (min-width: 900px) {
	.home-main {
		width: 60%;
	}

	.home-video {
		width: 35%;
	}

	.course-type {
		width: 33%;
	}
}

/* Course */

.courses {
	display: flex;
	flex-direction: column;
}

.course-list-item {
	margin-bottom: 1em;
}

.course-title {
	text-align: center;
	font-size: 1.5rem;
	margin-bottom: 0.5em;
}

/* Module */

.course-description {
	font-size: 1.2em;
	margin-top: 1.5em;
}

.module-list-item {
	margin-bottom: 10px;
}

.module-header,
.home {
	background: var(--base);
	padding: 50px;
	margin-bottom: 50px;
}

@media (min-width: 900px) {
	.header-main {
		width: 55%;
	}

	.intro-video {
		width: 35%;
	}
}

.embed-responsive {
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
}

.embed-responsive-item {
	position: absolute;
	height: 100%;
	width: 100%;
}

.content-box {
	display: flex;
	align-items: center;
	padding: 20px;
}

.content-title a:focus,
.content-title a:hover {
	color: #fe5e41;
}

.content-subtitle {
	display: flex;
	justify-content: space-between;
}

.icon {
	font-size: 2rem;
	min-width: 10%;
	display: inline-block;
	text-align: center;
	color: var(--base);
}

@media (min-width: 900px) {
}

.prog-buttons {
	display: flex;
	justify-content: center;
	margin: 30px auto 0;
}

.prog-buttons button {
	display: block;
	padding: 0.5em;
	border-radius: 50%;
	height: 50px;
	width: 50px;
	font-size: 1.5rem;
	text-transform: uppercase;
	font-weight: 700;
	border: 0;
	cursor: pointer;
	background: black;
	color: white;
	margin: 0 40px;
	text-align: center;
}

.prog-buttons button:focus,
.prog-buttons button:hover {
	outline: none;
	background: var(--blue);
}

/* Register and Login Forms */

.form-head {
	text-align: center;
	margin: 20px 0 10px;
}

.form-item {
	margin: 1em 0;
}

label {
	display: block;
	font-size: 0.8rem;
}

input[type="text"],
input[type="email"],
input[type="password"] {
	width: 100%;
	padding: 5px;
}

.form-err {
	font-size: 0.8rem;
	margin: 0.5em 0 0;
	color: red;
}

@media (min-width: 700px) {
	.auth-form {
		width: 50%;
		margin: 0 auto;
	}
}

.auth-error {
	width: 100%;
	background: lightcoral;
	padding: 10px;
	text-align: center;
	border-radius: 2px;
}

.auth-msg {
	margin: 0;
}
/* About */

.about-img {
	padding: 30px;
}

@media (min-width: 900px) {
	.about-header {
		width: 70%;
	}

	.about-img {
		width: 30%;
	}
}
